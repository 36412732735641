var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isBusy,"rounded":"sm"}},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"id":"frmBook"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-card',{staticClass:"editbook",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.isUpdateBookRequest ? 'Update Book' : 'Add New Book')+" ")]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.submitting}},[(_vm.submitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isUpdateBookRequest ? 'Update Book' : 'Add Book')+" ")],1),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"reset","disabled":_vm.submitting},on:{"click":function($event){$event.preventDefault();return _vm.reset($event)}}},[_vm._v("Reset")])]},proxy:true}])},[_c('b-card-body',{staticClass:"py-0"},[_c('p',[_c('small',[_vm._v("All fields with "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" are required. One of the fields with "),_c('span',{staticClass:"text-warning"},[_vm._v("*")]),_vm._v(" is required.")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ISBN(13):")]),_c('span',{staticClass:"ml-1 text-warning"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"ISBN13","rules":"isbn13|required_ifnot:@ISBN10,ISBN10","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.isbn_13),expression:"book.isbn_13"}],staticClass:"form-control",attrs:{"name":"book.isbn_13","type":"search","placeholder":"Enter ISBN13"},domProps:{"value":(_vm.book.isbn_13)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "isbn_13", $event.target.value)}}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.book.isbn_13,"size":"sm","title":"Lookup"},on:{"click":function($event){return _vm.lookupIsbn(_vm.book.isbn_13)}}},[_c('LookupIcon')],1)],1)],1),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ISBN(10):")]),_c('span',{staticClass:"ml-1 text-warning"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"ISBN10","rules":"isbn10|required_ifnot:@ISBN13,ISBN13","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.isbn_10),expression:"book.isbn_10"}],staticClass:"form-control",attrs:{"name":"book.isbn_10","type":"search","placeholder":"Enter ISBN10"},domProps:{"value":(_vm.book.isbn_10)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "isbn_10", $event.target.value)}}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.book.isbn_10,"size":"sm","title":"Lookup"},on:{"click":function($event){return _vm.lookupIsbn(null, _vm.book.isbn_10)}}},[_c('LookupIcon')],1)],1)],1),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Title:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.title),expression:"book.title"}],staticClass:"form-control",attrs:{"name":"booktitle","type":"text","placeholder":"Enter Title"},domProps:{"value":(_vm.book.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "title", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"}),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sub Title:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.subtitle),expression:"book.subtitle"}],staticClass:"form-control",attrs:{"name":"bookSubTitle","type":"text","placeholder":"Enter Sub Title"},domProps:{"value":(_vm.book.subtitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "subtitle", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Abstract:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.abstract),expression:"book.abstract"}],staticClass:"form-control",attrs:{"name":"bookAbstract","type":"text","placeholder":"Enter Abstract"},domProps:{"value":(_vm.book.abstract)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "abstract", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Authors:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Authors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{staticClass:"form-control",attrs:{"id":"bookAuthor","placeholder":"Add authors..."},model:{value:(_vm.book.author),callback:function ($$v) {_vm.$set(_vm.book, "author", $$v)},expression:"book.author"}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Publishers:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Publishers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{staticClass:"form-control",attrs:{"id":"bookPublisher","placeholder":"Add publishers..."},model:{value:(_vm.book.publisher),callback:function ($$v) {_vm.$set(_vm.book, "publisher", $$v)},expression:"book.publisher"}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Min Grade:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"MinGrade","rules":("between:" + _vm.minGrade + "," + _vm.maxGrade + "|required")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.min_grade),expression:"book.min_grade"}],staticClass:"form-control",attrs:{"name":"min_grade","type":"number","placeholder":"Minimum school grade","min":_vm.minGrade,"max":_vm.maxGrade},domProps:{"value":(_vm.book.min_grade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "min_grade", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Max Grade:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"MaxGrade","rules":("between:" + _vm.minGrade + "," + _vm.maxGrade + "|minValue:MinGrade|required")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.max_grade),expression:"book.max_grade"}],staticClass:"form-control",attrs:{"name":"max_grade","type":"number","placeholder":"Maximum school grade","min":_vm.minGrade,"max":_vm.maxGrade},domProps:{"value":(_vm.book.max_grade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "max_grade", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Published Year:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Published Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.published_year),expression:"book.published_year"}],staticClass:"form-control",attrs:{"name":"bookPublishedDate","type":"text","placeholder":"Enter Published Year"},domProps:{"value":(_vm.book.published_year)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "published_year", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Edition:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.edition),expression:"book.edition"}],staticClass:"form-control",attrs:{"name":"bookEdition","type":"text","placeholder":"Enter Edition"},domProps:{"value":(_vm.book.edition)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.book, "edition", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select Language:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.book.language_locale),expression:"book.language_locale"}],staticClass:"form-control",attrs:{"name":"bookLanguage"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.book, "language_locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',[_vm._v("Select a Language")]),_vm._l((_vm.AllLanguages),function(lang){return _c('option',{key:lang.locale,domProps:{"value":lang.locale}},[_vm._v(" "+_vm._s(lang.localizedName)+" ")])})],2),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Content Path:")]),_c('b-form-tags',{staticClass:"form-control",attrs:{"id":"bookContentPath","input-type":"url","placeholder":"Add links..."},model:{value:(_vm.book.content_path),callback:function ($$v) {_vm.$set(_vm.book, "content_path", $$v)},expression:"book.content_path"}})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cover Images:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Cover Images","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{staticClass:"form-control",attrs:{"id":"bookimage","input-type":"url","placeholder":"Add links..."},model:{value:(_vm.book.coverimage),callback:function ($$v) {_vm.$set(_vm.book, "coverimage", $$v)},expression:"book.coverimage"}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.book.coverimage)?_vm._l((_vm.book.coverimage),function(img,index){return _c('b-img',{key:index,staticClass:"thumbnail",attrs:{"src":img}})}):_vm._e()],2)])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }