<template>
  <b-overlay :show="isBusy" rounded="sm">
    <ValidationObserver ref="form">
      <form @submit.prevent="handleSubmit" id="frmBook">
        <b-card class="editbook">
          <template v-slot:header>
            {{ isUpdateBookRequest ? 'Update Book' : 'Add New Book' }}
          </template>
          <b-card-body class="py-0">
            <p>
              <small>All fields with <span class="text-danger">*</span> are required. One of the fields with <span class="text-warning">*</span> is required.</small>
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>ISBN(13):</label><span class="ml-1 text-warning">*</span>
                  <ValidationProvider name="ISBN13" rules="isbn13|required_ifnot:@ISBN10,ISBN10" mode="eager" v-slot="{ errors }">
                    <b-input-group>
                      <input v-model="book.isbn_13" name="book.isbn_13" class="form-control" type="search" placeholder="Enter ISBN13" />
                      <b-input-group-append>
                        <b-button variant="primary" :disabled="!book.isbn_13" @click="lookupIsbn(book.isbn_13)" size="sm" title="Lookup"><LookupIcon /></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>ISBN(10):</label><span class="ml-1 text-warning">*</span>
                  <ValidationProvider name="ISBN10" rules="isbn10|required_ifnot:@ISBN13,ISBN13" mode="eager" v-slot="{ errors }">
                    <b-input-group>
                      <input v-model="book.isbn_10" name="book.isbn_10" class="form-control" type="search" placeholder="Enter ISBN10" />
                      <b-input-group-append>
                        <b-button variant="primary" :disabled="!book.isbn_10" @click="lookupIsbn(null, book.isbn_10)" size="sm" title="Lookup"><LookupIcon /></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Title:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                    <input v-model="book.title" name="booktitle" class="form-control" type="text" placeholder="Enter Title" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-12"></div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Sub Title:</label>
                  <input v-model="book.subtitle" name="bookSubTitle" class="form-control" type="text" placeholder="Enter Sub Title" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Abstract:</label>
                  <textarea v-model="book.abstract" name="bookAbstract" class="form-control" type="text" placeholder="Enter Abstract" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Authors:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Authors" rules="required" v-slot="{ errors }">
                    <b-form-tags id="bookAuthor" v-model="book.author" class="form-control" placeholder="Add authors..." />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Publishers:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Publishers" rules="required" v-slot="{ errors }">
                    <b-form-tags id="bookPublisher" v-model="book.publisher" class="form-control" placeholder="Add publishers..." />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Min Grade:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="MinGrade" :rules="`between:${minGrade},${maxGrade}|required`" v-slot="{ errors }">
                    <input v-model="book.min_grade" name="min_grade" class="form-control" type="number" placeholder="Minimum school grade" :min="minGrade" :max="maxGrade" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Max Grade:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="MaxGrade" :rules="`between:${minGrade},${maxGrade}|minValue:MinGrade|required`" v-slot="{ errors }">
                    <input v-model="book.max_grade" name="max_grade" class="form-control" type="number" placeholder="Maximum school grade" :min="minGrade" :max="maxGrade" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Published Year:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Published Year" rules="required" v-slot="{ errors }">
                    <input v-model="book.published_year" name="bookPublishedDate" class="form-control" type="text" placeholder="Enter Published Year" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Edition:</label>
                  <input v-model="book.edition" name="bookEdition" class="form-control" type="text" placeholder="Enter Edition" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Select Language:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Language" rules="required" v-slot="{ errors }">
                    <select v-model="book.language_locale" name="bookLanguage" class="form-control">
                      <option>Select a Language</option>
                      <option v-for="lang in AllLanguages" :key="lang.locale" :value="lang.locale">
                        {{ lang.localizedName }}
                      </option>
                    </select>
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Content Path:</label>
                  <b-form-tags id="bookContentPath" v-model="book.content_path" input-type="url" class="form-control" placeholder="Add links..." />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Cover Images:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Cover Images" rules="required" v-slot="{ errors }">
                    <b-form-tags id="bookimage" v-model="book.coverimage" input-type="url" class="form-control" placeholder="Add links..." />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <template v-if="book.coverimage">
                    <b-img v-for="(img, index) in book.coverimage" :key="index" :src="img" class="thumbnail" />
                  </template>
                </div>
              </div>
            </div>
          </b-card-body>
          <template v-slot:footer>
            <button class="btn btn-primary" type="submit" :disabled="submitting">
              <b-spinner small v-if="submitting" />
              {{ isUpdateBookRequest ? 'Update Book' : 'Add Book' }}
            </button>
            <button class="btn btn-danger" type="reset" :disabled="submitting" @click.prevent="reset">Reset</button>
          </template>
        </b-card>
      </form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import LookupIcon from 'mdi-vue/FindReplace';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import lookupBookByIsbn from '@/store/modules/book/api';
import { supportedLanguages } from '@/i18n/lang';
import '@/util/validation';
import settings from '@/app.config';

export default {
  name: 'EditBookForm',
  components: { ValidationProvider, ValidationObserver, LookupIcon },
  props: {
    value: {
      type: Object,
      default: null,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      AllLanguages: supportedLanguages,
      book: {},
      submitting: false,
      checkingIsbn: false,
      minGrade: settings.schoolGrade.min,
      maxGrade: settings.schoolGrade.max,
    };
  },

  computed: {
    isBusy() {
      return this.busy || this.checkingIsbn;
    },
    isUpdateBookRequest() {
      return this.value && this.value.id;
    },
  },

  methods: {
    lookupIsbn(isbn13, isbn10) {
      const isbn = isbn13 || isbn10;
      if (!isbn || this.checkingIsbn) return;
      this.checkingIsbn = true;
      lookupBookByIsbn(isbn).then((book) => {
        const b = Object.assign(this.book, book);
        b.isbn_13 = isbn13 || b.isbn_13;
        b.isbn_10 = isbn10 || b.isbn_10;
        this.book = { ...b };
      }).catch(() => {}).finally(() => { this.checkingIsbn = false; });
    },
    handleSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) return this.$toast.error('Please check your input', 'Form Not Valid');

        const newBook = { ...this.book };
        newBook.min_grade = +newBook.min_grade;
        newBook.max_grade = +newBook.max_grade;
        newBook.lookup = false;
        // call the right handler based on incoming request
        if (this.isUpdateBookRequest) this.updateBook(newBook);
        else this.addBook(newBook);

        return false;
      });
    },
    addBook(newBook) {
      this.submitting = true;
      this.$store.dispatch('book/addBooks', [newBook]).then(() => {
        const msg = 'Book Added Successfully';
        this.$toast.success(msg);
        this.reset();
      }).catch(() => {
        this.$toast.error('Cannot add Book. Try again later.');
      }).finally(() => {
        this.submitting = false;
      });
    },
    updateBook(updBook) {
      this.submitting = true;
      this.$store.dispatch('book/updateBooks', [updBook]).then(() => {
        const msg = 'Book Updated Successfully';
        this.$toast.success(msg);
        this.$emit('input', updBook);
      }).catch(() => {
        this.$toast.error('Cannot update Book. Try again later.');
      }).finally(() => {
        this.submitting = false;
      });
    },
    reset() {
      const defaultBook = { min_grade: this.minGrade, max_grade: this.maxGrade, language_locale: settings.defaultLocale };
      this.$refs.form.reset();
      this.book = Object.assign({}, defaultBook, this.value);
    },
  },

  mounted() {
    this.reset();
  },
};
</script>

<style lang="stylus" scoped>
.editbook
 .form-group
   margin-bottom 0.5rem
   label
    margin-bottom 0
 .btn
   margin-right 1rem
 .thumbnail
   width 3rem
   margin 0.5rem 0.5rem 0 0

</style>
